import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'

import DocumentsSection from '../../components/quotation/pricing/DocumentsSection'
import TrustBox from '../../components/TrustBox'
import { useOnboardingWorkflow } from '../workflow'

import { OnboardingNextButton } from './OnboardingNextButton'
import { PromoCodeButton } from './PromoCodeButton'

export const PricingCard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { proceed } = useOnboardingWorkflow()

  return (
    <div>
      <div className="mx-auto w-min whitespace-nowrap rounded-t-md bg-primary-600 px-6 py-1.5 text-center text-sm font-medium text-white">
        Votre protection sur-mesure
      </div>

      <div className="flex flex-col gap-4 rounded-xl bg-primary-50 p-4 text-primary-900">
        <div className="flex flex-col gap-3">
          {children}

          <PromoCodeButton />

          <OnboardingNextButton onClick={() => proceed()}>
            <span>Souscrire</span>
            <FontAwesomeIcon icon={faArrowRight} />
          </OnboardingNextButton>
        </div>

        <DocumentsSection showQuote={false} />

        <TrustBox type="Micro-TrustScore" />
      </div>
    </div>
  )
}
