import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (element: HTMLElement, isAsync: boolean) => void
    }
  }
}

interface Props {
  className?: string
  type: 'Micro-TrustScore' | 'Review-Collector' | 'Custom-Review-Collector'
}

const getTemplateId = (type: Props['type']) => {
  switch (type) {
    case 'Micro-TrustScore':
      return '5419b732fbfb950b10de65e5'
    case 'Review-Collector':
      return '56278e9abfbbba0bdcd568bc'
    case 'Custom-Review-Collector':
      return ''
    default:
      throw Error('Unknown TrustBox type')
  }
}

const TrustBox: React.FC<Props> = ({ className, type }) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)

  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot && type !== 'Custom-Review-Collector') {
      if (ref.current) window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [ref, type])

  const templateId = useMemo(() => getTemplateId(type), [type])

  if (type === 'Custom-Review-Collector')
    return (
      <a
        href="https://fr.trustpilot.com/evaluate/olino.fr?utm_medium=trustbox&utm_source=TrustBoxReviewCollector"
        ref={ref}
        target="_blank"
        rel="noopener noreferrer"
        className={twMerge(
          'flex items-center justify-start rounded-xl px-6 py-2',
          'text-grey-800 text-opacity-70 hover:bg-grey-100 hover:text-primary-900',
          className
        )}
      >
        <div className={twMerge('opacity-50')}>
          <FontAwesomeIcon icon={faStar} />
        </div>
        <div className="ml-3">
          <div>Évaluez-nous</div>
          <div className="text-sm font-thin leading-tight opacity-80">sur Trustpilot</div>
        </div>
      </a>
    )

  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className={twMerge(
        'trustpilot-widget',
        type === 'Micro-TrustScore' && 'h-6',
        type === 'Review-Collector' && 'h-14',
        className
      )} // Renamed this to className.
      data-locale="fr-FR"
      data-template-id={templateId}
      data-businessunit-id="63456b472fc7811ff3cd59dc"
      data-style-height="100%"
      data-style-width="100%"
      data-theme="light"
    >
      <a href="https://fr.trustpilot.com/review/olino.fr" target="_blank" rel="noopener noreferrer">
        {' '}
        Trustpilot
      </a>
    </div>
  )
}
export default TrustBox
