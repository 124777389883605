import { useParams } from 'react-router'
import { useEffect } from 'react'

import { type OnboardingPage } from '../routes'
import Calendly from '../../components/Calendly'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { track } from '../../utils/analytics'

const Main = () => {
  const { id } = useParams()
  const { proceed } = useOnboardingWorkflow()

  const onProceed = () => {
    track({
      event: 'submitted_tailored_book_meeting',
      quotation_id: id,
    })

    proceed()
  }

  useEffect(() => {
    track({
      event: 'visited_tailored_book_meeting',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <div className="flex max-w-4xl flex-col items-center">
        <OnboardingTitle.H1 className="mb-3">
          Prenez rendez-vous avec un expert en ligne
        </OnboardingTitle.H1>

        <p className="text-center text-lg">
          Décrivez vos besoins à nos conseillers et{' '}
          <b>ils sauront vous proposer la meilleure offre !</b>
        </p>
      </div>

      <Calendly
        type="inline"
        styles={{ height: '1000px', width: '100%' }}
        reason="no-pricing"
        onEventScheduled={() => onProceed()}
      />
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
