/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type CSSProperties, useState } from 'react'
import {
  useCalendlyEventListener,
  InlineWidget,
  PopupModal,
  type EventScheduledEvent,
} from 'react-calendly'
import { isNil } from 'ramda'
import { type PartialDeep } from 'type-fest'
import { useParams } from 'react-router'

import { track } from '../utils/analytics'

// https://www.notion.so/olinofr/Reduce-un-necessary-user-support-touchpoints-11983c099d224ff6b5b30dcba091dddd
const CalendlyUrls = {
  default: 'https://calendly.com/olino/olino',
  help: 'https://calendly.com/olino/help15', // App01
  'cant-find-activity': 'https://calendly.com/olino/activity-selection15', // App02
  'no-pricing': 'https://calendly.com/olino/manual-product15', // App03
  'cant-find-product': 'https://calendly.com/olino/product-selection15', // App05
  'no-siret': 'https://calendly.com/olino/no-siret15', // App06
}

type Props = {
  styles?: CSSProperties
  reason?: keyof typeof CalendlyUrls
  onEventScheduled?: (e: EventScheduledEvent) => void
  data?: PartialDeep<{
    company: {
      name: string
      turnover: number
    }
    user: {
      firstName: string
      lastName: string
      email: string
      phone: string
    }
  }>
} & (
  | {
      type: 'inline'
      children?: never
    }
  | {
      type: 'popup'
      children: (open: () => void) => React.ReactNode
    }
)

const Calendly: React.FC<Props> = ({ type, styles, children, reason, data, onEventScheduled }) => {
  const [popupOpen, setPopupOpen] = useState(false)
  const { id } = useParams()

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      track({
        event: 'meeting_booked',
        quotation_id: id,
      })

      if (!isNil(onEventScheduled)) {
        onEventScheduled(e)
      }
    },
  })

  const props = {
    url: CalendlyUrls[reason || 'default'],
    styles: {
      ...styles,
    },
    prefill: {
      firstName: data?.user?.firstName || '',
      lastName: data?.user?.lastName || '',
      email: data?.user?.email || '',
      name: data?.user ? `${data?.user.firstName} ${data?.user.lastName}` : '',
      customAnswers: {
        a1: data?.user?.phone || '',
        a2: data?.company?.name || '',
        a3: data?.company?.turnover?.toString() || '',
      },
    },
  }

  if (type === 'inline') {
    return <InlineWidget {...props} />
  }

  if (type === 'popup') {
    return (
      <>
        {children(() => setPopupOpen(true))}
        <PopupModal
          onModalClose={() => setPopupOpen(false)}
          open={popupOpen}
          rootElement={document.getElementById('root')!}
          {...props}
        />
      </>
    )
  }

  return null
}

export default Calendly
